import React, { useMemo, useState } from "react";
import { styled } from "../../../stitches/stitches.config";
import { heading150, heading300 } from "../../../stitches/combinedStyles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Swiper as SwiperClass } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import ProductSlide, { ProductSlideProps } from "./ProductSlide";
import { useStaticQuery, graphql } from "gatsby";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  withArtDirection,
} from "gatsby-plugin-image";

const title = "Explore our products";

const slides: Omit<ProductSlideProps, "image">[] = [
  {
    title: "PLX-Benchtop",
    name: "PLX-Benchtop",
    byline:
      "The PLX-Benchtop is a compact mechanical testing device that extracts metal stress-strain curves from indentation test data in under five minutes.",
    shortByline: "Accurate stress-strain curves in less than 5 minutes",
    button: {
      path: "/benchtop",
      text: "Learn more",
    },
  },
  {
    title: "PLX-HotStage",
    name: "PLX-HotStage",
    byline:
      "The PLX-HotStage add-on module for our benchtop testing device that extracts mechanical properties from indentation test data at temperatures of up-to 800 °C",
    shortByline: "Measure mechanical properties at temperatures of up-to 800°C",
    button: {
      path: "/hotpip",
      text: "Learn more",
    },
  },
  {
    title: "PLX-Portable",
    name: "PLX-Portable",
    byline:
      "Scalable, accurate, and fast testing of high value metal assets in the field.",
    shortByline:
      "Scalable, accurate, and fast testing of high value metal assets in the field",
    button: {
      path: "http://plastometrex-6034553.hs-sites.com/pip-testing-for-pipeline-inspection",
      text: "Learn more",
      external: true,
    },
  },
  {
    title: "PLXUS",
    name: "PLXUS",
    byline:
      "PLXUS is our cloud-based digital platform for the collection, storage, and analysis of PIP test data. Identify trends, share and collaborate with colleagues, and track savings in cost and time.",
    shortByline:
      "Cloud-based collection, storage, and analysis of PIP test data",
    button: {
      path: null,
      text: "Coming soon",
    },
  },
];

const ProductDisplay = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass>();

  const data = useStaticQuery(graphql`
    query {
      benchtop: file(relativePath: { eq: "products/benchtop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1100, placeholder: TRACED_SVG)
        }
      }
      benchtopMobile: file(
        relativePath: { eq: "products/benchtopMobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1100
            quality: 90
            breakpoints: [300, 600]
            placeholder: TRACED_SVG
          )
        }
      }
      hotpip: file(relativePath: { eq: "products/hotpip.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1700, placeholder: TRACED_SVG)
        }
      }
      hotpipMobile: file(relativePath: { eq: "products/hotpipMobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1700
            quality: 90
            breakpoints: [300, 600]
            placeholder: TRACED_SVG
          )
        }
      }
      portable: file(relativePath: { eq: "products/portable.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1500, placeholder: TRACED_SVG)
        }
      }
      portableMobile: file(
        relativePath: { eq: "products/portableMobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1500
            quality: 90
            breakpoints: [300, 600]
            placeholder: TRACED_SVG
          )
        }
      }
      plxus: file(relativePath: { eq: "products/plxus.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, quality: 100, placeholder: TRACED_SVG)
        }
      }
    }
  `);
  const images: { img?: IGatsbyImageData; className: string }[] = useMemo(
    () => [
      {
        img: withArtDirection(getImage(data.benchtop)!, [
          {
            image: getImage(data.benchtopMobile)!,
            media: "(max-width: 999.97px)",
          },
        ]),
        className: "benchtop",
      },
      {
        img: withArtDirection(getImage(data.hotpip)!, [
          {
            image: getImage(data.hotpipMobile)!,
            media: "(max-width: 999.97px)",
          },
        ]),
        className: "hotpip",
      },
      {
        img: withArtDirection(getImage(data.portable)!, [
          {
            image: getImage(data.portableMobile)!,
            media: "(max-width: 999.97px)",
          },
        ]),
        className: "portable",
      },
      {
        img: getImage(data.plxus),
        className: "plxus",
      },
    ],
    [
      data.benchtop,
      data.benchtop,
      data.hotpip,
      data.hotpip,
      data.portable,
      data.portable,
      data.plxus,
      data.plxus,
    ]
  );

  return (
    <Wrapper>
      <Title>{title}</Title>
      <ButtonWrapper>
        {slides.map(({ name }, i) => (
          <ProductButton
            key={i}
            active={activeIndex === i}
            onClick={() => swiper?.slideTo(i)}
          >
            {name}
          </ProductButton>
        ))}
      </ButtonWrapper>
      <ForcePreloadImages>
        {images.map(({ img }, i) => (
          <GatsbyImage key={i} image={img!} alt="placeholder" />
        ))}
      </ForcePreloadImages>
      <StyledSwiper
        slidesPerView={1}
        onSlideChange={({ activeIndex }: SwiperClass) =>
          setActiveIndex(activeIndex)
        }
        onSwiper={setSwiper}
        navigation={true}
        modules={[Navigation]}
        preloadImages={true}
      >
        {slides.map((slide, i) => {
          return (
            <SwiperSlide key={slide.title}>
              <ProductSlide {...slide} image={images[i]} />
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </Wrapper>
  );
};

const Wrapper = styled("section", {
  backgroundColor: "$transparent",
  padding: 16,
  "@md": {
    padding: 32,
  },
  "@lg": {
    padding: 64,
  },
});



const Title = styled("h4", {
  ...heading150,
  marginBottom: 16,
  textAlign: "center",
  "@md": {
    ...heading300,
    marginTop: 25,
  },
});

const ButtonWrapper = styled("div", {
  margin: "auto",
  paddingBottom: 40,
  maxWidth: 600,
  justifyContent: "space-between",
  display: "none",
  "@lg": {
    display: "flex",
  },
});

const ProductButton = styled("button", {
  fontSize: 24,
  backgroundColor: "transparent",
  cursor: "pointer",
  border: "none",
  paddingBottom: 10,
  variants: {
    active: {
      true: {
        borderBottom: "4px solid $plxBlue",
      },
    },
  },
});

const StyledSwiper = styled(Swiper, {
  maxWidth: 1300,
  margin: "auto",
});

const ForcePreloadImages = styled("div", {
  position: "absolute",
  left: "50%",
  opacity: 0,
  display: "flex",
  height: 10,
  "& img": {
    height: 10,
  },
});
export default ProductDisplay;