import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { memo } from "react";
import {
  detailSmall,
  heading100,
  heading150,
  heading200,
  paragraphTextMedium,
} from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";
import Button, { ButtonProps } from "../Button/Button";
import HideOnScreen from "../HideOnScreen";

export interface ProductSlideProps {
  title: string;
  name: string;
  byline: string;
  shortByline: string;
  button: {
    path: string | null;
    text: string;
    external?: boolean;
  };
  image: { img?: IGatsbyImageData; className?: string };
}

const getLink = (
  button: ProductSlideProps["button"],
  size?: ButtonProps["size"]
) => {
  if (!button.path)
    return (
      <Button size={size} tier="disabled">
        {button.text}
      </Button>
    );

  if (button.external)
    return (
      <a href={button.path} target="_blank" rel="noreferrer">
        <Button size={size} arrow>
          {button.text}
        </Button>
      </a>
    );
  return (
    <Link to={button.path}>
      <Button size={size} arrow>
        {button.text}
      </Button>
    </Link>
  );
};

const ProductSlide = memo(
  ({
    title,
    byline,
    shortByline,
    button,
    image,
    image: { img, className: imgClass } = {} as any,
    ...rest
  }: ProductSlideProps) => {

    return (
      <Wrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <HideOnScreen variant="desktop" breakpoint="lg">
            <Byline>{byline}</Byline>
            {getLink(button)}
          </HideOnScreen>
          <HideOnScreen variant="mobile" breakpoint="lg">
            <Byline>{shortByline}</Byline>
            {getLink(button, "xSmall")}
          </HideOnScreen>
        </TextWrapper>
        {img && (
          <>
            {button.external ? (
              <ImageWrapper>
                <a href={button.path} target="_blank" rel="noreferrer">
                  <GatsbyImage
                    image={img}
                    loading="eager"
                    className={imgClass}
                    alt="title"
                  />
                </a>
              </ImageWrapper>
            ) : (
              <ImageWrapper>
                {button.path ? (
                  <Link to={button.path}>
                    <GatsbyImage
                      image={img}
                      loading="eager"
                      className={imgClass}
                      alt="title"
                    />
                  </Link>
                ) : (
                  <GatsbyImage
                    image={img}
                    loading="eager"
                    className={imgClass}
                    alt="title"
                  />
                )}
              </ImageWrapper>
            )}
          </>
        )}
      </Wrapper>
    );
  }
);

const Title = styled("h3", {
  textAlign: "center",
  ...heading100,
  marginTop: 4,
  marginBottom: 4,
  "@md": {
    marginBottom: 10,
    ...heading150,
  },
  "@lg": {
    textAlign: "left",
    ...heading200,
  },
});

const Wrapper = styled("div", {
  position: "relative",
  height: 300,

  overflow: "hidden",

  backgroundColor: "$white",
  border: "1px solid rgba(26, 26, 26, 0.1)",

  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",

  "@md": {
    height: 360,
  },
  "@lg": {
    height: 560,
    flexDirection: "row",
    marginRight: 64,
    marginLeft: 64,
    textAlign: "left",
  },
});

const TextWrapper = styled("div", {
  maxWidth: 350,
  margin: 16,
  zIndex: 3,
  "@lg": {
    margin: 64,
    marginRight: 0,
  },
  button: {
    margin: "auto",
    "@lg": {
      margin: 0,
    },
  },
});

const Byline = styled("p", {
  ...detailSmall,
  marginTop: 4,
  "@md": {
    marginTop: "1em",

    ...paragraphTextMedium,
  },
});

const ImageWrapper = styled("div", {
  maxWidth: "50%",

  "& .gatsby-image-wrapper": {
    height: "100%",
    width: "100%",
    transform: "translate(-50%, -5%)",
    left: "50%",
    "@lg": {
      transform: "unset",
      left: "unset",
    },
    "&.benchtop": {
      minWidth: 450,
      transform: "translate(-50%, -20%)",

      "@lg": {
        transform: "translate(10%, 10%)",
        left: "max(-200px, min(-163px, -5vw))",
        width: "max(600px, min(169%, 1100px))",
      },
    },
    "&.hotpip": {
      transform: "translate(-50%, -27%)",
      width: 400,

      "@lg": {
        transform: "translate(-6%, 8%)",
        left: "max(-200px, min(-135px, -5vw))",
        width: "max(600px, min(220%, 1700px))",
      },
    },
    "&.portable": {
      transform: "translate(-50%, -21%)",
      width: 400,
      "@lg": {
        transform: "translate(2%, 21%)",
        left: "max(-226px, min(-228px, -5vw))",
        width: "max(600px, min(220%, 1700px))",
      },
    },
    "&.plxus": {
      width: 300,
      transform: "translate(-48%, -13%)",
      "@md": {
        width: 350,
      },
      "@lg": {
        transform: "unset",

        left: "max(-200px, min(-110px, -5vw))",
        width: "max(600px, min(169%, 1100px))",
      },
    },
  },
});

export default ProductSlide;
